import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { StripePlugin } from '@vue-stripe/vue-stripe';

Vue.use(StripePlugin, {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
})
require('@/assets/style.css')
Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
