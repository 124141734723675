<template>

  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            v-model="companyName"
            :rules="companyRules"
            label="会社名"
            required
            ></v-text-field>

          <v-text-field
            v-model="name"
            :counter="64"
            :rules="nameRules"
            label="氏名"
            required
          ></v-text-field>

          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="メールアドレス"
            required
          ></v-text-field>

          <v-alert
            type="error"
            outlined
            class="alert"
          >
            ※来月から入会を選択された方は、今月開催のアカデミー会員様限定セミナーへのご参加や各種セミナーへの会員価格でのご参加いただくことができません。<br>
            ※今月から入会をご希望の場合は本日付けで1ヶ月分が課金されます。翌月以降は当該月の1日に課金されます。
          </v-alert>


          <v-select
            v-model="ticket"
            :items="ticketItems"
            :rules="[v => !!v || '初回チケットを選択して下さい。']"
            label="エンパワーメント経営アカデミーへ今月から入会されますか？"
            required
          ></v-select>

          <v-select
            v-model="course"
            :items="courseItems"
            :rules="[v => !!v || 'プランを選択して下さい。']"
            label="プラン"
            required
          ></v-select>

          <v-card
            elevation="2"
          >
            <v-card-title>
              カード情報
            </v-card-title>
            <v-card-text>
              <stripe-element-card
                ref="cardElement"
                :pk="publishableKey"
                :hidePostalCode="true"
                @token="tokenCreated"
                @error="tokenError"
              />
            </v-card-text>
          </v-card>

          <v-btn color="primary" elevation="2" @click="submit" class="button">登録{{envAlert}}</v-btn>
        </v-form>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import {StripeElementCard} from '@vue-stripe/vue-stripe'
import axios from 'axios'
import awsmobile from "../aws-exports"

export default {
  name: 'Input',
  components: {
    StripeElementCard,
  },
  data: function () {
    return {
      valid: true,
      overlay: false,
      memberId: '',
      companyName: '',
      companyRules: [
        v => !!v || '会社名を入力して下さい。',
      ],
      memberIdRules: [
        v => !!v || '会員番号を入力して下さい。',
        // v => /^[0-9]{6}$/.test(v) || '会員番号は6桁の数字で入力して下さい。'
      ],
      zip: '',
      zipRules: [
        v => !!v || '郵便番号を入力して下さい。',
        v => /^\d{3}\d{4}$/.test(v) || '郵便番号は7桁の半角数字で入力して下さい。'
      ],
      name: '',
      nameRules: [
        v => !!v || '氏名を入力して下さい。',
        v => (v && v.length <= 64) || '64文字以内で入力して下さい。',
        // v => /^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]+$/.test(v) || '氏名は全角で入力して下さい。'
      ],
      tel: '',
      telRules: [
        v => !!v || '電話番号を入力して下さい。',
        v => (v && v.length <= 11 && v.length >= 10) || '10文字以上11文字以下で入力して下さい。',
        v => /^[0-9]+$/.test(v) || '電話番号は半角数字で入力して下さい。'
      ],
      address: '',
      addressRules: [
        v => !!v || '住所を入力して下さい。',
        v => (v && v.length <= 128) || '128文字以内で入力して下さい。',
        // v => /^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]+$/.test(v) || '住所は全角で入力して下さい。'
      ],
      email: '',
      emailRules: [
        v => !!v || 'メールアドレスを入力して下さい。',
        v => /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(v) || 'メールアドレスの形式が正しくありません。問題が無いように見える場合はスペースが入っていないか確認して下さい。',
      ],
      course: null,
      courseItems: [],
      ticket: null,
      ticketItems: [],
      token: null,
      publishableKey: this.$stripe._apiKey,
      envAlert: null,
      page: '',
    }
  },
  mounted() {
    if (process.env.VUE_APP_ENV === 'dev') {
      this.envAlert = '（開発用）'
    } else if (process.env.VUE_APP_ENV === 'stg'){
      this.envAlert = '（テスト用）'
    }


    location.search.substring(1).split('&').forEach((item) => {
      const [key, value] = item.split('=')
      if (key === 'page') {
        switch (value) {
          case '1':
            this.page = '1'
            break
          case '2':
            this.page = '2'
            break
          default:
            this.page = ''
        }
      }
    })

    this.courseItems = JSON.parse(process.env[`VUE_APP_COURCES${this.page}`]).map((item) => { return {text:item.text, value: `${item.text.replace(/:/, '')}:${item.value}` }})
    this.ticketItems = JSON.parse(process.env[`VUE_APP_TICKETS${this.page}`]).map((item) => { return {text:item.text, value: `${item.text.replace(/:/, '')}:${item.value}` }})
      .concat([{"text":"来月から入会する","value":"--"}]),

    this.course = this.courseItems[0].value
  },
  methods: {
    submit() {
      const isValid = this.validate()
      if (!isValid) {
        return
      }
      this.overlay = true
      this.getToken()
    },
    getToken() {
      this.$refs.cardElement.submit()
    },
    async tokenCreated(token) {
      const body = {
        companyName: this.companyName,
        memberId: this.memberId,
        name: this.name,
        email: this.email,
        course: this.course,
        ticket: this.ticket,
        token,
      }
      const url = `${awsmobile.aws_cloud_logic_custom[0].endpoint}/transactions`
      try {
        const res = await axios.post(url, body)
        console.log(res)
        if (res.status !== 200) {
          alert('予期せぬエラーが起きました。')
        } else {
          console.log(res)
          if (res.data.status === 'error') {
            alert(res.data.message)
          } else {
            alert('登録完了')
            this.reset()
          }
        }
      } catch (error) {
        console.error(error)
        alert('エラーが起きました。')
      }
      this.overlay = false
    },
    tokenError(){
      this.overlay = false
    },
    validate() {
      return this.$refs.form.validate()
    },
    reset() {
      this.$refs.form.reset()
      this.$refs.cardElement.clear()
      this.$nextTick(() => {
        this.course = this.courseItems[0].value
      })
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
  },
}
</script>

<style scoped>
.button {
  margin-top: 20px;
}
.alert {
  text-align: left;
}
</style>
