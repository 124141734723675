<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        サブスクリプション
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <Input/>
    </v-main>
  </v-app>
</template>

<script>
import Input from './components/Input';

export default {
  name: 'App',

  components: {
    Input,
  },

  data: () => ({
    //
  }),
};
</script>
